<template>
    <MyCard :height="height" :width="width">
        <template v-slot:header>
            Filters
            <v-spacer />
            <div class="float-right">
                <FilterAdd :filters="filters" />
            </div>
        </template>
        <v-data-table
            dense
            class="row-pointer"
            :items="filters"
            :headers="headers"
            :hide-default-footer="filters.length < 11"
            @click:row="click"
            rounded
            background-color="transparent"
            no-data-text="You have no custom filters"
        >
            <template #item.remove="{ item }">
                <FilterDelete :filter="item" />
            </template>
        </v-data-table>
    </MyCard>
</template>

<script>
import FilterAdd from "@/components/FilterAdd.vue";
import FilterDelete from "@/components/FilterDelete.vue";
import MyCard from "@/components/MyCard.vue";

import { ref, onMounted } from "@vue/composition-api";
import { useRouter } from "vue2-helpers/vue-router";
import axios from "axios";

export default {
    props: ["height", "width"],
    components: {
        FilterAdd,
        MyCard,
        FilterDelete,
    },
    setup() {
        const router = useRouter();

        const filters = ref([]);

        const headers = [
            { text: "Name", value: "name", show: true, width: "20%" },
            { text: "Used by # profiles", value: "profiles", show: true, width: "20%" },
            { text: "IPv4 entries", value: "ipv4_entries", show: true, width: "20%", align: "center", sortable: false },
            { text: "IPv6 entries", value: "ipv6_entries", show: true, width: "20%", align: "center", sortable: false },
            { text: "Delete", value: "remove", width: "5%", align: "center", sortable: false },
        ];

        function click(item) {
            router.push(`/filters/${item.guid}`);
        }

        onMounted(() => {
            axios.get(`/api/v1/filters/private`).then((response) => {
                filters.value = response.data;
            });
        });
        return {
            filters,
            headers,
            click,
        };
    },
};
</script>
