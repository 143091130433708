<template>
    <MyCard :height="height" :width="width">
        <template v-slot:header>
            BGP Peers
            <v-spacer />
            <ButtonNew :action="actionNew" />
        </template>
        <v-data-table
            class="row-pointer"
            :items="peers"
            :headers="headers"
            dense
            :hide-default-footer="peers.length <= 4"
            @click:row="click"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [10, 25, 50, -1],
            }"
            no-data-text="You have no BGP peers."
        >
            <template #item.status="{ item }">
                <div v-if="item.nodes.length == 0">(no nodes)</div>
                <v-progress-circular v-else-if="peerStatusLoading" indeterminate color="primary" size="24" />
                <div v-else>
                    <div v-if="Object.keys(peerStatus).length > 0">
                        <div v-for="node in item.nodes" :key="node.guid">
                            <div v-if="node.short_code in peerStatus[item.internal_name]">
                                {{ node.short_code }} - {{ peerStatus[item.internal_name][node.short_code].state }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #item.lastChange="{ item }">
                <div v-if="item.nodes.length == 0">(no nodes)</div>
                <v-progress-circular v-else-if="peerStatusLoading" indeterminate color="primary" size="24" />
                <div v-else>
                    <div v-if="Object.keys(peerStatus).length > 0">
                        <div v-for="node in item.nodes" :key="node.guid">
                            <div v-if="node.short_code in peerStatus[item.internal_name]">
                                {{ peerStatus[item.internal_name][node.short_code].last_change | ts_ago }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </v-data-table>
    </MyCard>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import MyCard from "@/components/MyCard.vue";
import ButtonNew from "@/components/ButtonNew.vue";

import { useRouter } from "vue2-helpers/vue-router";

import axios from "axios";
// README https://stackoverflow.com/questions/68256028/forbidden-csrf-cookie-not-set-when-sending-post-delete-request-from-vue-js-to
axios.defaults.xsrfHeaderName = "x-csrftoken";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

export default {
    props: ["height", "width"],
    components: {
        ButtonNew,
        MyCard,
    },
    setup() {
        const router = useRouter();

        const peers = ref([]);
        const isLoaded = ref(false);
        const add_btn_color = ref("");
        const peerStatus = ref({});
        const peerStatusLoading = ref(true);
        const headers = [
            { text: "Name", value: "name", width: "20%" },
            { text: "IP", value: "ip", width: "20%" },
            { text: "ASN", value: "asn_peer", width: "20%" },
            { text: "Status", value: "status", width: "20%" },
            { text: "Last Change", value: "lastChange", width: "20%" },
        ];

        function actionNew() {
            router.push("/peers/new");
        }

        function click(item) {
            router.push(`/peers/${item.guid}`);
        }

        onMounted(() => {
            axios.get(`/api/v1/peers/`).then((response) => {
                isLoaded.value = true;
                peers.value = response.data;

                var peer_guids = [];
                for (var i in peers.value) {
                    peer_guids.push(peers.value[i].guid);
                }
                if (peer_guids.length == 0) {
                    return;
                }

                const postData = {
                    peer_guids: peer_guids,
                };
                axios.post("/api/v1/peer_status", postData).then((response) => {
                    peerStatus.value = response.data;
                    peerStatusLoading.value = false;
                });
            });
        });

        return {
            peers,
            isLoaded,
            add_btn_color,
            peerStatus,
            peerStatusLoading,
            headers,
            actionNew,
            click,
        };
    },
};
</script>
