<template>
    <MyCard :height="height" :width="width">
        <template v-slot:header>
            Profiles
            <v-spacer />
            <ProfileAdd :profiles="profiles" />
        </template>
        <v-data-table
            dense
            class="row-pointer"
            :items="profiles"
            :headers="headers"
            :hide-default-footer="profiles.length < 11"
            @click:row="click"
            rounded
            background-color="transparent"
            no-data-text="You have no profiles. Create one by clicking the New button."
        >
            <template #item.filters="{ item }">{{ item.filters.length }}</template>
            <template #item.bgp_peers="{ item }">{{ item.bgp_peers.length }}</template>
        </v-data-table>
    </MyCard>
</template>

<script>
import ProfileAdd from "@/components/ProfileAdd.vue";
import MyCard from "@/components/MyCard.vue";

import { ref, onMounted } from "@vue/composition-api";
import axios from "axios";
import { useRouter } from "vue2-helpers/vue-router";

export default {
    props: ["height", "width"],
    components: {
        ProfileAdd,
        MyCard,
    },
    setup() {
        const router = useRouter();

        const profiles = ref([]);

        const headers = [
            { text: "Name", value: "name", show: true, width: "20%" },
            { text: "Filters", value: "filters", show: true, width: "20%" },
            { text: "Peers", value: "bgp_peers", show: true, width: "20%" },
            { text: "IPv4 prefixes", value: "ipv4_entries", show: true, width: "20%" },
            { text: "IPv6 prefixes", value: "ipv6_entries", show: true, width: "20%" },
        ];

        function click(item) {
            router.push(`/profiles/${item.guid}`);
        }

        onMounted(() => {
            axios.get(`/api/v1/profiles/`).then((response) => {
                profiles.value = response.data;
            });
        });
        return {
            profiles,
            headers,
            click,
        };
    },
};
</script>
