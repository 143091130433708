<template>
    <v-container>
        <v-row dense>
            <v-col cols="6" style="height: 40vh;">
                <Profiles height="100%" style="overflow-y: scroll" />
            </v-col>
            <v-col cols="6" style="height: 40vh;">
                <Filters height="100%" style="overflow-y: scroll" />
            </v-col>
        </v-row>
        <v-row dense style="height: 40vh">
            <v-col cols="12">
                <WidgetBGPPeers height="100%" />
            </v-col>
        </v-row>
        <v-dialog v-model="displayTrialInfo" width="600">
            <v-card>
                <v-card-title class="text-h5 primary">
                    Welcome to {{ constants.app_name }}.
                </v-card-title>

                <v-card-text class="text-body-1 pa-4">
                    <p>
                    At the moment your account is on a free non-commercial plan.
                    </p>
                    <p>
                    You are welcome to take a look around, use it for free for non-commercial purposes, and <a href="/subscription">start your free 30-day trial</a>
                    by upgrading to a paid plan when you are ready.
                    </p>
                    <p>Contact us any time with your questions, suggestions or wishes at <a :href="`mailto:hello@${constants.domain}`">hello@${{constants.domain}}</a> and we'll do our best to help you.</p>
                </v-card-text>
                <v-card-actions>
                    <v-checkbox
                        label="Don't show again"
                        v-model="dontShowAgain"
                        color="primary accent-4"
                    />
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Profiles from "@/components/Profiles.vue";
import WidgetBGPPeers from "@/components/WidgetBGPPeers.vue";
// import WidgetTier from "@/components/WidgetTier.vue";
import Filters from "@/components/Filters.vue";

import { ref, onMounted, getCurrentInstance } from "@vue/composition-api";
import { useConstants } from "@/const/const.js";
import { useStore } from "@/store/index.js";

import Cookies from 'universal-cookie';

export default {
    props: {},
    emits: ["breadcrumb"],
    components: {
        WidgetBGPPeers,
        // WidgetTier,
        Filters,
        Profiles,
    },
    setup() {
        const { emit } = getCurrentInstance();
        const store = useStore();

        const constants = useConstants();
        const dontShowAgain = ref(false);
        const cookies = new Cookies();

        const displayTrialInfo = ref(store.state.session.displayTrialInfo && !cookies.get("dontShowTrialInfo"));

        onMounted(() => {
            emit("breadcrumb", []);
        });

        function close() {
            displayTrialInfo.value = false;
            if (dontShowAgain.value) {
                cookies.set('dontShowTrialInfo', 1);
            }
        }

        return {
            displayTrialInfo,
            dontShowAgain,
            constants,
            close,
        };
    },
};
</script>
