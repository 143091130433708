<template>
    <v-dialog v-model="dialog" width="500">
        <TierLimitReached :snackbar="snackbar" />
        <template v-slot:activator="{ on }">
            <ButtonNew :action="on.click" />
        </template>
        <v-card class="rounded-lg">
            <v-card-title class="text-h6 primary">Create a new Profile</v-card-title>
            <v-text-field class="ma-6" v-model="profileName" label="Profile name" @keyup="keyup($event)" autofocus />
            <v-divider />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn width="50%" rounded color="green" text @click="add" :disabled="addDisabled">Add</v-btn>
                <v-btn width="50%" rounded color="red" text @click="close">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ButtonNew from "@/components/ButtonNew.vue";
import TierLimitReached from "@/components/TierLimitReached.vue";
import { ref } from "@vue/composition-api";
import { useRouter } from "vue2-helpers/vue-router";
import axios from "axios";

export default {
    props: ["profiles"],
    components: {
        ButtonNew,
        TierLimitReached,
    },
    setup(props) {
        const router = useRouter();

        const profileName = ref("");
        const dialog = ref(false);
        const add_btn_color = ref("");
        const errorMessages = ref("");
        const successMessages = ref("");
        const addDisabled = ref(false);

        const btn_color = ref("");
        const snackbar = ref("");

        function keyup(event) {
            if (event.key === "Enter") {
                add();
                return;
            }

            for (var i in props.profiles) {
                if (profileName.value === props.profiles[i].name) {
                    addDisabled.value = true;
                    errorMessages.value = "A profile of this name already exists.";
                } else {
                    addDisabled.value = false;
                    errorMessages.value = "";
                }
            }
        }

        function add() {
            if (profileName.value === "") return;
            addDisabled.value = true;

            const data = {
                name: profileName.value,
            };

            axios
                .post(`/api/v1/profiles/`, data)
                .then((response) => {
                    successMessages.value = "Profile added successfully!";
                    router.push(`/profiles/${response.data.guid}`);
                })
                .catch((error) => {
                    if (error.response.status == 405) {
                        snackbar.value = true;
                        addDisabled.value = false;
                    }
                });
        }

        function close() {
            profileName.value = "";
            dialog.value = false;
            errorMessages.value = "";
            successMessages.value = "";
            addDisabled.value = false;
        }

        return {
            profileName,
            dialog,
            add_btn_color,
            errorMessages,
            successMessages,
            addDisabled,
            btn_color,
            snackbar,
            keyup,
            add,
            close,
        };
    },
};
</script>
